import { colors } from "../colors/colors.js"
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import moment from "moment";

export default {
  state: () => ({
    isDark: colors.palette.active.dark,
    colors: colors,
    imgFundos: {
      "light": "https://image.winudf.com/v2/image1/Y29tLnFhbmplcnVtYmkud2hpdGUud2hpdGViYWNrZ3JvdW5kX3NjcmVlbl8wXzE1NDE5NzAyMDlfMDQ0/screen-0.jpg?fakeurl=1&type=.jpg",
      "dark": "https://i.picsum.photos/id/1019/5472/3648.jpg?hmac=2mFzeV1mPbDvR0WmuOWSiW61mf9DDEVPDL0RVvg1HPs",
    },
    isLogRecursos: false,
    isLog: false,
    user: null,
    areaCor: {},
    ucRecursosSel: "",
    recursosA2: {
      ucs: [],
      profs: [],
    },
    admin: {
      equipesByID: {},
      equipesByName: {},
      equipesCalendarByID: {},
      equipesCalendarByName: {},
      loaded: false,
    },
    lastUserAdmin: null,
  }),
  mutations: {
    setLastUserAdmin(state, value) {
      state.lastUserAdmin = value;
    },

    loadAdminRecursos(state) {
      var self = state;
      rdb.ref('/usuarios/equipes').on('value', function(snapshot) {
        self.admin.equipesByID = snapshot.val();
        for(var idx in self.admin.equipesByID) {
          self.admin.equipesByName[self.admin.equipesByID[idx].nome] = self.admin.equipesByID[idx];
        }
        rdb.ref('/calendarios/2021S2/equipes').on('value', function(snapshot) {
          self.admin.equipesCalendarByID = snapshot.val();
          for(var idx in self.admin.equipesCalendarByID) {
            self.admin.equipesCalendarByName[self.admin.equipesCalendarByID[idx].nome] = self.admin.equipesCalendarByID[idx];
          }
          //console.log("ADMIN recursos carregado!");
          self.admin.loaded = true;
        });
      });
    },

    loadRecursos(state) {
      var self = state;
      rdb.ref('/recursosA2/professores').on('value', function(snapshot) {
        self.recursosA2.profs = snapshotValToArray(snapshot.val());
        for(var i in self.recursosA2.profs) {
          if(self.recursosA2.profs[i].Nome != undefined) {
            self.recursosA2.profs[i].nome = self.recursosA2.profs[i].Nome;
            self.recursosA2.profs[i].NOME = self.recursosA2.profs[i].Nome;
          } else {
            if(self.recursosA2.profs[i].NOME != undefined) {
              self.recursosA2.profs[i].nome = self.recursosA2.profs[i].NOME;
              self.recursosA2.profs[i].Nome = self.recursosA2.profs[i].NOME;
            } else {
              self.recursosA2.profs[i].NOME = self.recursosA2.profs[i].nome;
              self.recursosA2.profs[i].Nome = self.recursosA2.profs[i].nome;
            }
          }
        }
        //console.log("recursos profs carregado!");
        rdb.ref('/recursosA2/ucs2021S2').on('value', function(snapshot) {
          self.recursosA2.ucs = snapshotValToArray(snapshot.val());
          //console.log("recursos UCs carregado!");
        });
      });
    },

    setUCSel(state, value) {
      state.ucRecursosSel = value;
    },

    initAreaColors(state) {
      rdb.ref('/areas').on('value', function(snapshot) {
        var areas = snapshot.val();
        //console.log("initAreaColors",areas);
        for(var key in areas) {
          state.areaCor[key] = areas[key].cor;
        }
      });
    },

    toggleDark (state) {
      state.isDark = !state.isDark;
      //console.log(state.colors);
      var active = state.colors.palette.active;
      state.colors.palette.active = state.colors.palette.inverse;
      state.colors.palette.inverse = active;
      //console.log(state.colors);
      return;
      var colors = state.colors.palette;
      var P = colors.P;
      colors.P = colors.S;
      colors.S = P;
      var K = colors.K;
      colors.K = colors.B;
      colors.B = K;
      state.colors.palette = colors;
      //console.log(state.colors);
    },
    updateUser(state, params) {
      state.user = Object.assign({}, params);
    },
    login (state, params) {
      state.isLog = true;
      //console.log("self :",params.self);
      //console.log("user :",params.user);
      params.self.$session.start();
      params.self.$session.set('login', true);
      var user = Object.assign({}, params.user);
      params.self.$session.set('userID', user.id);
      user.isGestor = function() {
        var cargos = [ "Gestor(a) de Área", "Assessor(a) de Área" ];
        return (cargos.indexOf(this.cargo) != -1 || this.admin);
      }
      rdb.ref('/curriculo/ofertas/2021S2/').orderByChild("profID").equalTo(user.id).on('value', function(snapshot) {
        var ofertas = snapshot.val();
        //console.log("ofertas",ofertas);
        var minhasUCs = {};
        for(var key in ofertas) {
          minhasUCs[ofertas[key].ucID] = true;
        }
        //console.log("minhasUCs",minhasUCs);
        user.minhasUCs = minhasUCs;
      });
      state.user = user;
      rdb.ref("/usuarios/pessoasLogControl/"+user.id+"/id").set(user.id);
      rdb.ref("/usuarios/pessoasLogControl/"+user.id+"/backlogs/"+generatePushID()()).set(moment().format());
      rdb.ref("/usuarios/pessoasLogControl/"+user.id+"/logged").set(true);
      rdb.ref("/usuarios/pessoasLogControl/"+user.id+"/last").set(moment().format());
      params.self.$router.push({ name: "Home" });
    },
    logout (state) {
      state.isLog = false;
      state.isLogRecursos = false;
      rdb.ref("/usuarios/pessoasLogControl/"+state.user.id+"/logged").set(false);
      //console.log("deslogado");
    },
    loginRecursos (state, params) {
      state.isLogRecursos = true;
      //console.log("self :",params.self);
      //console.log("user :",params.user);
      params.self.$session.start();
      params.self.$session.set('loginRecursos', true);
      var user = Object.assign({}, params.user);
      params.self.$session.set('userIDRecursos', user.id);
      state.user = user;
    },
    logoutRecursos (state) {
      state.isLogRecursos = false;
      //console.log("deslogado");
    },
    loginLinkedin (state) {
      state.isLog = true;
    }
  },
  actions: {
  },
  getters: {

  }
}
