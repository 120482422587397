import chroma from "chroma-js";

export const mixcolors = {
  data: function () {
    return {

    }
  },
  computed: {
    areaCor() {
      return this.$store.state.geral.areaCor;
    },
    palette() {
      return this.$store.state.geral.colors.palette;
    },
    cor() {
      return this.$store.state.geral.colors.palette;
    },
    isDark(){
      return false; //this.$store.state.isDark;
    },
    bgImg() {
      if(this.$store.state.geral.isDark) {
        return this.$store.state.geral.imgFundos.dark;
      }
      return this.$store.state.geral.imgFundos.light;
    }
  },
  created: function () {

  },
  methods: {

    toggleDark() {
      this.$store.commit('toggleDark')
      //console.log(this.$store.state.isDark);
    },

    getChroma: function() {
      return this.$store.state.geral.colors.chroma;
    },

    hexTints(cor,percentage = 0) {
      let hue = chroma(cor).get('hsl.h')
      let saturation = chroma(cor).get('hsl.s')
      let luminance = chroma(cor).get('hsl.l')
      let lighter = luminance + (percentage * (1-luminance))
      return lighter <= 1 ? chroma(hue, saturation, lighter, 'hsl').hex() : "#ffffff";
    },

    hexShades(cor,percentage = 0) {
      let hue = chroma(cor).get('hsl.h')
      let saturation = chroma(cor).get('hsl.s')
      let luminance = chroma(cor).get('hsl.l')
      //console.log(luminance);
      let darker = luminance - (percentage * luminance)
      //console.log(darker);
      return darker > 0 ? chroma(hue, saturation, darker, 'hsl' ).hex() : "#000000";
    }
  }
}
