import { rdb, ServerValue } from '@/firebasedb.js';

function generateConteudoID(sigla,nomeUC) {
  var siglaArea = sigla.replaceAll("&","");
  var siglaUC = "";
  var nome = nomeUC.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  var vet = nome.trim().split(" ");
  if(vet.length == 1) {
    siglaUC = nome.substr(0,3);
  } else {
    for(var idx in vet) {
      if(idx == 0) {
        siglaUC = siglaUC + vet[idx].trim().substr(0,3);
      } else {
        if(vet[idx].length > 3) {
          siglaUC = siglaUC + vet[idx].trim().substr(0,1).toUpperCase();
        }
      }
    }
  }
  return siglaArea+"-"+siglaUC;
}

export default {
  namespaced: true,
  state: () => ({
    conteudos: {
      conteudos: {},
      contadores: { total: 0 },
      loading: true,
      iniciado: false,
    }
  }),
  mutations: {
    setIniciado(state) {
      state.conteudos.iniciado = true;
    },
    setTotal(state,value) {
      console.log("value",value);
      state.conteudos.contadores.total = value;
      state.conteudos.loading = false;
    },
    setConteudos(state,value) {
      console.log("value",value);
      state.conteudos.conteudos = value;
      state.conteudos.loading = false;
    },
  },
  actions: {
    init(context) {
      console.log("init:",context.state.conteudos.iniciado);
      if(!context.state.conteudos.iniciado) {
        rdb.ref('/curriculo/conteudos/contadores/total').on('value', function(snap) {
          console.log("snap.val()",snap.val());
          if(snap.val() == null) {
            context.commit('setTotal',0)
          } else {
            context.commit('setTotal',snap.val());
          }
        });
        rdb.ref('/curriculo/conteudos/conteudos').on('value',function(snap) {
          if(snap.val() == null) {
            context.commit('setConteudos',{})
          } else {
            context.commit('setConteudos',snap.val());
          }
        });
        context.commit('setIniciado');
      }
    },
    add({ commit },value) {
      console.log("add",value);
      rdb.ref("/curriculo/conteudos/contadores/").update({total: ServerValue.increment(1)});
      rdb.ref("/curriculo/conteudos/contadores/").update({proxID: ServerValue.increment(1)});
      rdb.ref("/curriculo/conteudos/contadores/proxID").get().then(function(snap) {
        var total = snap.val();
        console.log("total",total);
        if(total == null) {
          total = 0;
        }
        var updates = {};
        var newKey = "CTD"; //generateConteudoID(value.areaSigla,value.ucNome);
        var num = ""+total;
        while (num.length < 5) num = "0" + num;
        newKey = newKey + "-" + num;
        updates["/curriculo/conteudos/conteudos/"+newKey+"/id"] = newKey;
        updates["/curriculo/conteudos/conteudos/"+newKey+"/texto"] = value.texto;
        updates["/curriculo/conteudos/conteudos/"+newKey+"/ucs/"+value.uc.id] = { id: value.uc.id, nome: value.uc.nome };
        updates["/curriculo/conteudos/ucs/"+value.uc.id+"/id"] = value.uc.id;
        updates["/curriculo/conteudos/ucs/"+value.uc.id+"/nome"] = value.uc.nome;
        updates["/curriculo/conteudos/ucs/"+value.uc.id+"/conteudos/"+newKey+"/id"] = newKey;
        updates["/curriculo/conteudos/ucs/"+value.uc.id+"/conteudos/"+newKey+"/texto"] = value.texto;
        console.log("updates",updates);
        rdb.ref().update(updates);
        rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id).update({qtde: ServerValue.increment(1)});
        rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id+"/ordem").get().then(function(snap) {
          var ordem = snap.val();
          if(ordem == null) {
            ordem = newKey;
          } else {
            ordem = ordem+";"+newKey;
          }
          rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id+"/ordem").set(ordem);
        });
      });
    },

    update({ commit },value) {
      console.log("update",value);
      var updates = {};
      var newKey = value.conteudo.id;
      updates["/curriculo/conteudos/conteudos/"+newKey+"/ucs/"+value.uc.id] = { id: value.uc.id, nome: value.uc.nome };
      updates["/curriculo/conteudos/ucs/"+value.uc.id+"/id"] = value.uc.id;
      updates["/curriculo/conteudos/ucs/"+value.uc.id+"/nome"] = value.uc.nome;
      updates["/curriculo/conteudos/ucs/"+value.uc.id+"/conteudos/"+newKey+"/id"] = newKey;
      updates["/curriculo/conteudos/ucs/"+value.uc.id+"/conteudos/"+newKey+"/texto"] = value.conteudo.texto;
      console.log("updates",updates);
      // AUD-MecSisAgrPre-0001
      // AUD-MecSAP-0001
      rdb.ref().update(updates);
      rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id).update({qtde: ServerValue.increment(1)});
      rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id+"/ordem").get().then(function(snap) {
        var ordem = snap.val();
        if(ordem == null) {
          ordem = newKey;
        } else {
          ordem = ordem+";"+newKey;
        }
        rdb.ref("/curriculo/conteudos/ucs/"+value.uc.id+"/ordem").set(ordem);
      });
    },

    remove({ commit },value) {
      console.log("remove",value);
      rdb.ref("/curriculo/conteudos/contadores/").update({total: ServerValue.increment(-1)});
      var updates = {};
      updates["/curriculo/conteudos/conteudos/"+value.id] = null;
      console.log("updates",updates);
      rdb.ref().update(updates);
    },

  },
  getters: {

  }
}
