import chroma from "chroma-js";

hexTints = function (cor,percentage) {
  let hue = chroma(cor).get('hsl.h')
  let saturation = chroma(cor).get('hsl.s')
  let luminance = chroma(cor).get('hsl.l')
  let lighter = luminance + (percentage * (1-luminance))
  return lighter <= 1 ? chroma(hue, saturation, lighter, 'hsl').hex() : "#ffffff";
}

hexShades = function (cor,percentage) {
  let hue = chroma(cor).get('hsl.h')
  let saturation = chroma(cor).get('hsl.s')
  let luminance = chroma(cor).get('hsl.l')
  //console.log(luminance);
  let darker = luminance - (percentage * luminance)
  //console.log(darker);
  return darker > 0 ? chroma(hue, saturation, darker, 'hsl' ).hex() : "#000000";
}

export const v_color = {
  directiveFunc: function(el,binding,vnode) {
    //if(binding.arg == undefined) { console.log("diretive v-c error!"); return; }
    if(binding.arg == undefined) { return; }
    var args = binding.arg.split("#");
    if(args == null || args.length == 0) { return; }
    var palette = args[0] != undefined ? args[0] : "P";
    var tone = args[1] != undefined ? args[1] : "L";
    var value = args[2] != undefined ? parseInt(args[2]) : 0;
    const store = vnode.context.$store;
    var cor = store.state.geral.colors.palette.active[palette];
    //console.log(palette,tone,value,cor);
    if((tone == "Lt") && (value > 0)) {
      cor = hexTints(cor,value/10);
      //console.log(cor);
    }
    if((tone == "Dk") && (value > 0)) {
      cor = hexShades(cor,value/10);
    }
    el.style.color = cor;
    el.style.fill = cor;
  },
  bind: function (el,binding,vnode) {
    v_color.directiveFunc(el,binding,vnode);
  },
  update: function (el,binding,vnode) {
    v_color.directiveFunc(el,binding,vnode);
    //console.log();
  }
};

export const v_colorHex = {
  directiveFunc: function(el,binding,vnode) {
    //if(binding.arg == undefined) { console.log("diretive v-c error!"); return; }
    if(binding.value == undefined) { return; }
    el.style.color = binding.value;
    el.style.fill = binding.value;
  },
  bind: function (el,binding,vnode) {
    v_colorHex.directiveFunc(el,binding,vnode);
  },
  update: function (el,binding,vnode) {
    v_colorHex.directiveFunc(el,binding,vnode);
    //console.log();
  }
};
