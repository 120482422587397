//import { rdb, snapshotValToArray } from '@/firebase.js'

export const mixlogin = {
  data: function () {
    return {

    }
  },
  computed: {
    isLog() {
      return this.$store.state.geral.isLog;
    },
    userLog() {
      return this.$store.state.geral.user;
    },
    isUserTemporary() {
      return (this.$store.state.geral.lastUserAdmin != null);
    },
    isUserAdmin() {
      return (this.$store.state.geral.user.equipes != undefined && this.$store.state.geral.user.equipes["-Me1wkpw1Jby_ywz16iI"] != undefined);
    },
    isLogRecursos() {
      return this.$store.state.geral.isLogRecursos;
    },
    isAdminRecursosLoad() {
      return this.$store.state.geral.admin.loaded;
    },
    isGestor() {
      return (this.$store.state.geral.user.cargo == "Gestor(a) de Área" && this.$store.state.geral.user.areasIC[this.area.id] != undefined);
    },
    isGestores() {
      var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
      return (gestores.indexOf(this.$store.state.geral.user.cargo) != -1 && this.$store.state.geral.user.areasIC[this.area.id] != undefined);
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {

    getUserAreasIC() {
      var user = this.$store.geral.state.user;
      var areas = [];
      for(var key in user.areasIC) {
        areas.push(user.areasIC[key].sigla);
      }
      return areas;
    },

    logout() {
      this.log("logout!");
      this.$session.destroy();
      this.$store.commit("logout");
    },

    loginFromSession(func) {
      var self = this;
      this.$session.start();
      if((this.$session.has("login")) && (this.$session.get("userID"))) {
        this.rdb.ref('/usuarios/pessoas/'+this.$session.get("userID")).once('value', function(snapshot) {
          var user = snapshot.val();
          //self.log(user)
          if(user == null) {
            self.log_e('Usuário não encontrado!');
            func();
            return;
          }
          //console.log(user);
          self.log_s("nome " + user.nome + " já logado!");
          self.$store.commit("login", { "self": self, "user": user });
          func();
        });
      } else {
        self.log_e('Usuário não logado!');
        func();
      }
    },

    loginFromSessionRecursos(func) {
      var self = this;
      this.$session.start();
      if((this.$session.has("loginRecursos")) && (this.$session.get("userIDRecursos"))) {
        this.rdb.ref('/recursosA2/professores/'+this.$session.get("userIDRecursos")).once('value', function(snapshot) {
          var user = snapshot.val();
          self.log(user)
          if(user == null) {
            self.rdb.ref('/usuarios/pessoas/'+self.$session.get("userIDRecursos")).once('value', function(snapshot) {
              var user = snapshot.val();
              self.log(user)
              if(user == null) {
                self.log_e('Usuário não encontrado!');
                func();
                return;
              }
              //console.log(user);
              self.log_s("nome " + user.Nome + " já logado!");
              user.Sigla = user.Areas;
              self.$store.commit("loginRecursos", { "self": self, "user": user });
              func();
            });
          } else {
            //console.log(user);
            self.log_s("nome " + user.Nome + " já logado!");
            self.$store.commit("loginRecursos", { "self": self, "user": user });
            func();
          }
        });
      } else {
        self.log_e('Usuário não logado!');
        func();
      }
    }


  }
}
