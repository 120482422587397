import chroma from "chroma-js";
window.chroma = chroma;

export const hexTints = function (cor,percentage) {
  let hue = chroma(cor).get('hsl.h')
  let saturation = chroma(cor).get('hsl.s')
  let luminance = chroma(cor).get('hsl.l')
  let lighter = luminance + (percentage * (1-luminance))
  return lighter <= 1 ? chroma(hue, saturation, lighter, 'hsl').hex() : "#ffffff";
}
window.hexTints = hexTints;

export const hexShades = function (cor,percentage) {
  let hue = chroma(cor).get('hsl.h')
  let saturation = chroma(cor).get('hsl.s')
  let luminance = chroma(cor).get('hsl.l')
  //console.log(luminance);
  let darker = luminance - (percentage * luminance)
  //console.log(darker);
  return darker > 0 ? chroma(hue, saturation, darker, 'hsl' ).hex() : "#000000";
}
window.hexShades = hexShades;

export const colorScale = function(pal, corField, cor) {
  pal[corField]["dk"] = chroma(cor).get('hsl.l') < 0.5 ? "#ffffff" : "#000000";
  pal[corField]["llll"] = hexTints(cor,0.7);
  pal[corField]["lll"] = hexTints(cor,0.5);
  pal[corField]["ll"] = hexTints(cor,0.2);
  pal[corField]["l"] = hexTints(cor,0.05);
  pal[corField]["d"] = hexShades(cor,0.05);
  pal[corField]["dd"] = hexShades(cor,0.2);
  pal[corField]["ddd"] = hexShades(cor,0.5);
  pal[corField]["dddd"] = hexShades(cor,0.7);
}

export const buildPalette = function(pal) {
  colorScale(pal,"P", pal.P._);
  colorScale(pal,"S", pal.S._);
  colorScale(pal,"I", pal.I._);
  colorScale(pal,"W", pal.W._);
  colorScale(pal,"V", pal.V._);
  colorScale(pal,"D", pal.D._);
  colorScale(pal,"B", pal.B._);
  colorScale(pal,"K", pal.K._);
  return pal;
}

export const buildPalette2 = function(pal) {
  pal.M = chroma.mix(pal.L,pal.D).hex();
  colorScale(pal,"M", pal.M);
  colorScale(pal,"D", pal.D);
  colorScale(pal,"DD", pal.DD);
  colorScale(pal,"L", pal.L);
  colorScale(pal,"LL", pal.LL);
  return pal;
}
