import Vue from 'vue'
import Vuex from 'vuex'
import geral from './modules/geral.js';
import counter from './modules/teste.js';
import { moduloTeste } from './modules/teste0.js';
import conteudos from './modules/conteudos.js';
import topicos from './modules/topicos.js';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    geral, conteudos, topicos
  }
})
