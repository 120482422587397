
import Notify from 'simple-notify'
import './mixtoast.css'
import { icons } from "./mixtoasticons.js"

//window.Notify = Notify;

export const mixtoast = {
  data: function () {
    return {
      lastToast: null
    }
  },
  created: function () {
    //this.logSuccess("created")
  },
  mounted: function () {
    //this.logSuccess("mounted")
  },
  methods: {
    toast: function (type, title, text, timeout, icon = null, colors = null) {
      //console.log("toast: ",text);
      if(this.lastToast != null) {
        this.lastToast.close();
      }
      this.lastToast = new Notify({ status: type, title: title, text: text, speed: 700, autotimeout: timeout,
        effect: 'fade',
        customClass: null,
        customIcon: icon,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'right top'
      });
      if(colors != null) {
        document.querySelector('.notify--type-1').style.backgroundColor = colors.bkg;
        document.querySelector('.notify--type-1').style.border = "1px solid "+colors.border;
        document.querySelector('.notify__title').style.color = colors.title;
        document.querySelector('.notify__text').style.color = colors.text;
        document.querySelector('.notify__close svg path').setAttribute("fill", colors.close);
      }
    },
    toastIcon(icon,color) {
      var innerSVG = icons[icon];
      return '<span style="fill: '+color+'"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" version="1.1">'+innerSVG+'</svg></span>';
    },
    toastColors(cor) {
      return {
        bkg: this.cor[cor].llll,
        border: this.cor[cor].dd,
        title: this.cor[cor].dd,
        text: this.cor.K._,
        close: this.cor[cor].dd,
      };
    },
    toastClear() {
      if(this.lastToast != null) {
        this.lastToast.close();
      }
    },
    toastS(text) {
      this.toast("success", "Mensagem", text, 4000, this.toastIcon("done",this.cor.V._),this.toastColors("V"));
    },
    toastW(text) {
      this.toast("warning", "Aviso", text, 4000, this.toastIcon("warning",this.cor.W.d),this.toastColors("W"));
    },
    toastI(text) {
      this.toast("info", "Informação", text, 4000, this.toastIcon("info",this.cor.I.d),this.toastColors("I"));
    },
    toastD(text) {
      this.toast("danger", "Erro", text, 4000, this.toastIcon("error",this.cor.D.d),this.toastColors("D"));
    },


  }
}
