import chroma from "chroma-js";

hexTints = function (cor,percentage) {
  let hue = chroma(cor).get('hsl.h')
  let saturation = chroma(cor).get('hsl.s')
  let luminance = chroma(cor).get('hsl.l')
  let lighter = luminance + (percentage * (1-luminance))
  return lighter <= 1 ? chroma(hue, saturation, lighter, 'hsl').hex() : "#ffffff";
}

hexShades = function (cor,percentage) {
  let hue = chroma(cor).get('hsl.h')
  let saturation = chroma(cor).get('hsl.s')
  let luminance = chroma(cor).get('hsl.l')
  //console.log(luminance);
  let darker = luminance - (percentage * luminance)
  //console.log(darker);
  return darker > 0 ? chroma(hue, saturation, darker, 'hsl' ).hex() : "#000000";
}

export const v_bgcolor = {
  directiveFunc: function(el,binding,vnode) {
    //if(binding.arg == undefined) { console.log("diretive v-c error!"); return; }
    if(binding.arg == undefined) { return; }
    var args = binding.arg.split("#");
    //console.log(args);
    if(args == null || args.length == 0) { return; }
    var palette = (args[0] != undefined) && (args[0] != NaN) ? args[0] : "P";
    var transparent = (args[1] != undefined) && (args[1] != "") ? parseInt(args[1]) : 0;
    var tone = args[2] != undefined ? args[2] : "Lt";
    var value = args[3] != undefined ? parseFloat(args[3].replace("@",".")) : 0;
    const store = vnode.context.$store;
    var cor = store.state.geral.colors.palette.active[palette];
    //console.log("v_bgcolor",palette, transparent, tone, value, cor);
    if((tone == "Lt") && (value > 0)) {
      cor = hexTints(cor,value/10);
    }
    if((tone == "Dk") && (value > 0)) {
      cor = hexShades(cor,value/10);
    }
    if(transparent > 0) {
      cor = cor + Math.floor((255 / 10)*parseInt(transparent)).toString(16);
    }
    //console.log("v_bgcolor",cor);
    el.style.backgroundColor = cor;
  },
  bind: function (el,binding,vnode) {
    v_bgcolor.directiveFunc(el,binding,vnode);
  },
  update: function (el,binding,vnode) {
    v_bgcolor.directiveFunc(el,binding,vnode);
    //console.log();
  }
};
