<template>
  <span class="svg-icon">
    <span :style="'fill: '+color">
      <svg v-html="innerSVG" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" version="1.1"></svg>
    </span>
  </span>
</template>

<script>
import { icons } from "./icons.js"

export default {
  name: 'duoicon',
  props: [ "color", "icon" ],
  data(){
    return {
      innerSVG: "",
    }
  },
  watch: {
    icon: function (val) {
      this.innerSVG = icons[val];
    },
  },
  created() {
  },
  mounted() {
    this.innerSVG = icons[this.icon];
  },
  methods: {

  }
}

</script>

<style>

.svg-icon {
  display: inline-flex;
  align-self: center;
}
.svg-icon svg {
  height: 1em;
  width: 1em;
  top: .125em;
  position: relative;
}

.duoicon-btn svg {
  height: 2em;
  width: 2em;
}

</style>
