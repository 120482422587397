<template>
  <span>
    {{texto}}
  </span>
</template>

<script>
import { faker } from '@/components/dummy/faker.js'

export default {
  name: 'dummy',
  components: { },
  props: ['attr'],
  computed: {
  },
  data() {
    return {
      texto: "dummy"
    }
  },
  mounted() {
    //console.log("DUMMY Mounted");
    var elems = this.attr.split(".");
    this.texto = faker.get(elems[0],elems[1]);
  },
  methods: {
  }
}
</script>
