import Vue from 'vue'
import router from './router'
import store from './store'
// BootstrapVue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import vuetify from './plugins/vuetify'

window.vuetify = vuetify;

Vue.config.productionTip = false

import VueSession from '@/components/vue-session.js'
Vue.use(VueSession)

import "@/components/duoicon/duoicon.js"
import "@/components/dummy/dummy.js"
import '@/mmastyle/index.css'

import Particles from "particles.vue";
Vue.use(Particles);

export const eventBus = new Vue();

import mainplugin from "./plugins/mainplugin.js";
Vue.use(mainplugin);

import "@/linkedinauth.js"

import moment from 'moment';
import VueMoment from 'vue-moment';
// Load Locales ('en' comes loaded by default)
require('moment/locale/pt-br');
// Choose Locale
moment.locale('pt-br');
Vue.use(VueMoment, { moment });

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('treeselect', Treeselect)

import VueProgressiveImage from 'vue-progressive-image'
Vue.use(VueProgressiveImage, {
  delay: 2000 // 2 seconds before the image is displayed
});

import JsonEditor from 'vue-json-edit'
Vue.use(JsonEditor)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import VueFormulate from '@braid/vue-formulate'
import '@braid/vue-formulate/themes/snow/snow.scss';
import { pt } from '@braid/vue-formulate-i18n'
Vue.use(VueFormulate,  {
  plugins: [ pt ],
  locale: 'pt'
})

//import App from './App.vue'

var App = () => import('./App.vue')

if(window.location.href.indexOf("/plugin") != -1) {
  App = () => import('./views/public/router2.vue');
} else {
  if(window.location.href.indexOf("/public") != -1) {
    App = () => import('./views/public/router.vue');
  } else {
    if(window.location.href.indexOf("/appcalendario2022s1") != -1) {
      App = () => import('./views/appCalendario2022s1/appCalendario.vue');
    } else {
      if(window.location.href.indexOf("/appcalendario") != -1) {
        App = () => import('./views/appCalendario/appCalendario.vue');
      } else {
        if(window.location.href.indexOf("/labs") != -1) {
          App = () => import('./views/labs/appLabs2.vue');
        } else {
          if(window.location.href.indexOf("/recursos2021S2") != -1) {
            App = () => import('./views/recursos2021S2/router.vue');
          }
        }
      }
    }
    //if(window.location.href.indexOf("/multirao") != -1) {
    //  App = () => import('./views/labs/appMultirao.vue');
    //}
  }
}


var vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

export const $vue = vue;

//import AppRecursos from './views/Recursos/AppRecursos.vue'

/*
if(window.location.href.indexOf("/calendario") == -1) {
  vue = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
} else {
  if(window.location.href.indexOf("/recursos") == -1) {
    vue = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  } else {
    vue = new Vue({
      router,
      store,
      vuetify,
      render: h => h(AppRecursos)
    }).$mount('#app')
  }
}
*/
