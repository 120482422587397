//import { rdb, snapshotValToArray } from '@/firebase.js'

var fak = require('faker-br');

var globalUsers = [];

export const faker  = {
  initGlobalUsers: function() {
    var self = this;
    this.rdb.ref('/pessoas/').once('value').then(function(snapshot) {
      var ret = self.snapshotValToArray(snapshot.val());
      console.log("globalUsers");
      console.log(ret);
      globalUsers = ret;
    });
  },

  getRamdonRealUser: function() {
    var idx = Math.floor((globalUsers.length) * Math.random());
    return globalUsers[idx];
  },

  get: function(attr1, attr2) {
    if(attr1 == undefined) return "dummy";
    if(attr2 == undefined) return "dummy";
    //console.log(attr1,attr2);
    return fak[attr1][attr2]();
  },

  getUser: function() {
    return {
      NOME: faker.get("name","firstName") + " " + faker.get("name","lastName"),
      Cargo: "Professor(a)",
      urlFoto: faker.profileImg(),
      Formacao: "", GrandeArea: 2, Marca: "USJT", Regional: "SP",
      Titulacao: "Mestre", Unidade: "", email: "", Telefone: "", senha: "xxxx", admin: false,
    };
  },

  profileImg: function() {
    var urls = [];
    for(var i=1; i < 15; i++) {
      urls.push("https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/dummy-profiles%2F"+i+".jpg?alt=media");
    }
    var pos = (function(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    })(1,14);
    //console.log(urls);
    //console.log(pos);
    return urls[pos];
  }
};
