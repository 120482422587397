import { duotone01 } from "@/plugins/icons/duotone01.js"
import { icIconsDuo } from "@/plugins/icons/ic-duo.js"
import { areasicones } from "@/plugins/icons/areasicones.js"

export const v_icon = {
  // When the bound element is inserted into the DOM...
  directiveFunc: function(el,binding) {
    var args = binding.arg.split("#");
    if(args == null || args.length < 2) {
      //console.log(binding.arg);
      el.innerHTML = `<span class="p-1 circle fs-8pt fw-300 f-lato red text-white" style="border: 1px solid black;">ier</span>`;
      return;
    }
    var iconCollection = args[0] != undefined ? args[0] : "ph";
    var iconName = binding.value != undefined ? binding.value : args[1] != undefined ? args[1] : "error";
    var iconSize = args[2] != undefined ? args[2] : "22";
    //console.log(args,iconCollection,iconName,iconSize);
    var viewBox = "0 0 256 256";
    var icons = duotone01;
    if(iconCollection == "ph" || iconCollection == "duo") { icons = duotone01; }
    if(iconCollection == "ic") { icons = icIconsDuo; viewBox = "0 0 24 24" }
    if(iconCollection == "ar") { icons = areasicones; viewBox = icons[iconName].viewBox; }
    if(icons[iconName] == undefined) {
      el.innerHTML = `<span class="p1 circle fs-8pt fw-300 f-lato red text-white" style="border: 1px solid black;">ier</span>`;
    } else {
      el.innerHTML = `<span style="display: inline-flex; align-self: center; top: .325em; position: relative;"><svg class="sz`+iconSize+`" viewBox="`+viewBox+`">`+icons[iconName].body+`</svg></span>`;
    }
  },
  bind: function (el,binding) {
    v_icon.directiveFunc(el,binding);
  },
  update: function (el,binding) {
    v_icon.directiveFunc(el,binding);
  }
}
