import logdown from 'logdown';

var msg = logdown("APP MIXINS", { prefixColor: '#009d52' });
msg.state.isEnabled = true;
msg.log("EXECUTED %c\u2714","color: #009d52; font-size: 13pt;");
window.logColor = function(color) {
  console.log(" %c "+color+"   ","background-color: "+color+";");
}

export const mixconsole = {
  created: function () {
    //this.logSuccess("created")
  },
  mounted: function () {
    //this.logSuccess("mounted")
  },
  methods: {
    log: function (texto) {
      var msg = logdown(this.$options.name, { prefixColor: '#009d52' });
      msg.state.isEnabled = true;
      msg.log(texto+" %c\u2714","color: #37009d; font-size: 13pt;");
    },

    log_s: function(texto) {
      var msg = logdown(this.$options.name, { prefixColor: '#009d52' });
      msg.state.isEnabled = true;
      msg.log(texto+" %c\u2714","color: #009d52; font-size: 13pt;");
    },

    log_e: function(texto) {
      var msg = logdown(this.$options.name + " error", { prefixColor: '#9d0013' });
      msg.state.isEnabled = true;
      msg.log("%c\u2297%c"+texto,"color: #9d0013; font-size: 15pt;","margin-left: 6px; color: #60020d; font-size: 9pt;");
    },

  }
}
