import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
import "firebase/auth"

firebase.initializeApp({
  apiKey: "AIzaSyC_QSAB-P4MSRe9QY8vT64i3-C-vrEMMc4",
  authDomain: "appintegra-d8424.firebaseapp.com",
  databaseURL: "https://appintegra-d8424-default-rtdb.firebaseio.com",
  projectId: "appintegra-d8424",
  storageBucket: "appintegra-d8424.appspot.com",
  messagingSenderId: "549034408889",
  appId: "1:549034408889:web:7bb99a3385bcc59c1b5468"
})

export const db = firebase.firestore()
export const rdb = firebase.database()
export const ServerValue = firebase.database.ServerValue
export const fbst = firebase.storage();

if(location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  //rdb.useEmulator("localhost", 9000);
}
//window.firebase = firebase;

function initAuth() {
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      // User is signed in.
      //console.log("user auth ok");
      user.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        // Send token to your backend via HTTPS
        // ...
        //console.log("idToken",idToken.substr(0,30));
        //console.log("idToken",idToken);
      }).catch(function(error) {
        // Handle error
        //console.log("error",error);
      });
    } else {
      // No user is signed in.
    }
  });

  firebase.auth().signInWithEmailAndPassword("appintegra.lab@gmail.com", "miroma")
  .then((userCredential) => {
    // Signed in
    var user = userCredential.user;
    //console.log("userCredential.user ok",userCredential.user);
    //console.log("userCredential.user ok");
    // ...
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    //console.log("erro",error);
  });
}

initAuth();

window.clone = function(obj) {
  var ret = JSON.parse(JSON.stringify(obj));
  return ret;
}
window.clone2 = function(value) {
  if(typeof value != 'object'){
    return  value
  }
  var obj = value;
  var newObj;
  if (obj instanceof Array) {
    newObj = []
  } else if(typeof obj == 'object'){
    newObj = {}
  }
  for (let k in obj) {
    let v = obj[k]
    if (obj instanceof Array) {
      newObj.push(clone2(v))
    } else
    if(typeof obj === 'object'){
      newObj[k] = clone2(v)
    } else {
      console.log("CLONE should not arrive at this point");
    }
  }
  return newObj
}

window.objToArray = function(obj) {
  var vet = [];
  for (var key in obj) {
    vet.push(obj[key]);
  }
  return vet;
}


//window.rdb = rdb;
//db.settings({timestampsInSnapshots: true });

export function generatePushID() {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars = [];

  return function() {
    var now = new Date().getTime();
    var duplicateTime = (now === lastPushTime);
    lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0) throw new Error('We should have converted the entire timestamp.');

    var id = timeStampChars.join('');

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if(id.length != 20) throw new Error('Length should be 20.');

    return id;
  };
}

export function join(primaryKeyPath, key, secoundaryPath, cb) {
  var primRef = rdb.ref(primaryKeyPath);
  var getElementsAtPrimaryKey = function(key,callback) {
    primRef.child(key).on('child_added', function(snap) {
      let itemRef = rdb.ref(secoundaryPath).child(snap.id)
      itemRef.once("value",callback);
    })
  }

  primRef.limitToFirst(2).once("value",function(snap) {
    var snapshot = snap.val();
    for(var key in snapshot) {
      console.log(key,snapshot[key]);
      getElementsAtPrimaryKey(key, function(snap) {
        console.log(snap.val());
      });
    }
  })
  //getElementsAtPrimaryKey()
}

export function updateKey(path, newValue, callback) {
  console.log("updateKey path: "+path, newValue);
  var updates = {};
  updates[path] = newValue;
  rdb.ref().update(updates);
  callback();
}

export function relationsFromSnapshot(snapshot, pathSource, callback) {
  var promises = [];
  var snapshotVet = snapshot.val();
  if(snapshotVet == null) {
    callback([]);
    return;
  }
  delete snapshotVet.count;
  for(var key in snapshotVet) {
    //console.log(key);
    promises.push(rdb.ref(pathSource).child(key).once('value'));
  }
  Promise.all(promises).then(function(values) {
    var ret = [];
    for(var i=0; i<values.length; i++) {
      if(values[i].val() != null) {
        var obj = values[i].val();
        obj[".key"] = values[i].key;
        ret.push(obj);
      }
    }
    callback(ret);
  });
}

export function snapshotValToArray(snap) {
  var vet = [];
  for(var idx in snap) {
    snap[idx].id = idx;
    vet.push(snap[idx]);
  }
  return vet;
}

export function objToArray(snap) {
  var vet = [];
  for(var idx in snap) {
    vet.push(snap[idx]);
  }
  return vet;
}

export function snapshotValToArraySort(snap, sortKey, desc = false) {
  var vet = [];
  for(var idx in snap) {
    snap[idx].id = idx;
    vet.push(snap[idx]);
  }
  function compare( a, b ) {
    if ( a[sortKey] < b[sortKey] ){
      return (desc ? 1 : -1);
    }
    if ( a[sortKey] > b[sortKey] ){
      return (desc ? -1 : 1);
    }
    return 0;
  }
  vet.sort( compare );
  //console.log(vet)
  return vet;
}

export function relations(pathIDs, pathSource, callback) {
  rdb.ref(pathIDs).once("value", function(snapshot) {
    var promises = [];
    var snapshotVet = snapshot.val();
    delete snapshotVet.count;
    for(var key in snapshotVet) {
      //console.log(key);
      promises.push(rdb.ref(pathSource).child(key).once('value'));
    }
    Promise.all(promises).then(function(values) {
      var ret = [];
      for(var i=0; i<values.length; i++) {
        var obj = values[i].val();
        obj[".key"] = values[i].key;
        ret.push(obj);
      }
      callback(ret);
    });
  });
}

export function rdbSnapshotFirstObj(snapshot) {
  const data = snapshot.val() || null;
  if(data) {
    const id = Object.keys(data)[0];
    data[id][".key"] = id
    return(data[id]);
  } else {
    return null;
  }
}

export function snapshotToArray(querySnapshot) {
  var vet = [];
  querySnapshot.forEach(function(doc) {
    //console.log(doc.id, " => ", doc.data());
    var rec = doc.data();
    rec.id = doc.id;
    vet.push(rec);
  });
  return vet;
};

export function addDoc(collectionName, obj) {
  db.collection(collectionName).add(obj);
}

export function addDocThen(collectionName, obj, func) {
  db.collection(collectionName).add(obj).then(func);
}

export function updateDoc(collectionName, obj) {
  db.collection(collectionName).doc(obj.id).update(obj);
}

export function updateDocThen(collectionName, obj, func) {
  db.collection(collectionName).doc(obj.id).update(obj).then(func);
}

export function removeDoc(collectionName, obj, funcOk) {
  db.collection(collectionName).doc(obj.id).delete().then(funcOk).catch(function(error) {
    console.error("Error removing document: ", error);
  });
}

export function updateDocWithKey(collectionName, key, keyValue, obj) {
  db.collection(collectionName).where(key, "==", keyValue).get().then(function(querySnapshot) {
    if(querySnapshot.size == 0) console.log("updateDocWithKey Error: key ["+keyValue+"] not found!");
    db.collection(collectionName).doc(querySnapshot.docs[0].id).update(obj);
    console.log("Update sended for key ["+keyValue+"]");
    //console.log(querySnapshot.docs[0].data());
    //console.log(querySnapshot.docs[0].id);
  }).catch(function(error) {
    console.log("Error getting document:", error);
  });
}

export function updateAddDocWithKey(collectionName, key, keyValue, obj) {
  db.collection(collectionName).where(key, "==", keyValue).get().then(function(querySnapshot) {
    if(querySnapshot.size == 0) {
      console.log("updateDocWithKey Error: key ["+keyValue+"] not found! ===> CREATING DOC...");
      db.collection(collectionName).add(obj);
    } else {
      db.collection(collectionName).doc(querySnapshot.docs[0].id).update(obj);
      console.log("Update sended for key ["+keyValue+"]");
    }
    //console.log(querySnapshot.docs[0].data());
    //console.log(querySnapshot.docs[0].id);
  }).catch(function(error) {
    console.log("Error getting document:", error);
  });
}

export function getObjFromDocWithKey(collectionName, key, keyValue, func) {
  db.collection(collectionName).where(key, "==", keyValue).get().then(function(querySnapshot) {
    if(querySnapshot.size == 0) console.log("getObjFromDocWithKey Error: key ["+keyValue+"] not found in collection: "+collectionName);
    var elem = querySnapshot.docs[0].data();
    elem.id = querySnapshot.docs[0].id;
    func(elem);
  }).catch(function(error) {
    console.log("Error getting document:", error);
  });
}
