import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: () => import('@/views/home/Home.vue') },
  //{ path: '/', name: 'Home', component: () => import('@/views/cursos/planosv2/lab/planos01.vue') },

  //LABS
  //{ path: '/lab01', name: 'lab01', component: () => import('@/views/lab01/Lab01.vue') },
  //{ path: '/lab02', name: 'lab02', component: () => import('@/views/lab02/Lab02.vue') },
  //{ path: '/labs/icons', name: 'icons', component: () => import('@/views/lab03/Lab03.vue') },
  //{ path: '/labs/rdbconsole', name: 'rdbconsole', component: () => import('@/views/labs/rdbconsole/rdbconsole.vue') },
  //{ path: '/labs/rtdb/:id', name: 'rtdb', props: true, component: () => import('@/views/labs/rdbconsole/rtdb.vue') },
  //{ path: '/labs/clab01', name: 'clab01', component: () => import('@/views/labs/calendar/lab01.vue') },

  { path: '/labs/calendar', name: 'calendar', component: () => import('@/views/labs/calendar/lab01.vue') },

  { name: 'appcalendario', path:'/appcalendario/:id', props: true, component: () => import('@/views/appCalendario/routerCalendario.vue') },
  { name: 'appcalendario2022s1', path:'/appcalendario2022s1/:id', props: true, component: () => import('@/views/appCalendario2022s1/routerCalendario.vue') },

  //{ path: '/labs/plugin01', name: 'plugin01', component: () => import('@/views/labs/plugin/plugin01.vue') },

  //{ path: '/labs/planos01', name: 'planos01', component: () => import('@/views/labs/planos/planos01.vue') },
  //{ path: '/labs/planos02', name: 'planos02', component: () => import('@/views/labs/planos/planos02.vue') },
  //{ path: '/labs/planos03', name: 'planos03', component: () => import('@/views/labs/planos/planos03.vue') },
  //{ path: '/labs/ofertas01', name: 'ofertas01', component: () => import('@/views/labs/planos/ofertas01.vue') },
  //{ path: '/labs/ofertas02', name: 'ofertas02', component: () => import('@/views/labs/planos/ofertas02.vue') },
  //{ path: '/labs/metas01', name: 'metas01', component: () => import('@/views/labs/planos/metas01.vue') },
  //{ path: '/labs/metas02', name: 'metas02', component: () => import('@/views/labs/planos/metas02.vue') },
  //{ path: '/labs/metas03', name: 'metas03', component: () => import('@/views/labs/planos/metas03.vue') },
  //{ path: '/labs/metas04', name: 'metas04', component: () => import('@/views/labs/planos/metas04.vue') },
  //{ path: '/labs/metas', name: 'metas', component: () => import('@/views/labs/planos/metas/metas.vue') },

  //{ path: '/labs/questoes', name: 'questoes', component: () => import('@/views/labs/planos/questoes/questoes.vue') },
  //{ path: '/labs/encomendas3', name: 'encomendas3', component: () => import('@/views/labs/encomendas/encomendas3.vue') },
  //{ path: '/labs/encomendas4', name: 'encomendas4', component: () => import('@/views/labs/encomendas/encomendas4.vue') },
  //{ path: '/labs/tubbie', name: 'tubbie', component: () => import('@/views/labs/tubbie/tubbie.vue') },
  //{ path: '/labs/addAreasEncomendas', name: 'tubbie', component: () => import('@/views/labs/simple/addAreasEncomendas.vue') },
  //{ path: '/labs/stores', name: 'stores', component: () => import('@/views/labs/stores/stores.vue') },

  //{ path: '/labs/metaseng', name: 'metaseng', component: () => import('@/views/labs/planos/metasEng.vue') },
  //{ path: '/labs/metasnovas', name: 'metasnovas', component: () => import('@/views/labs/planos/metasNovas.vue') },
  //{ path: '/multirao/mmc', name: 'multirao', component: () => import('@/views/labs/multirao/mmc.vue') },
  //{ path: '/labs/testepost01', name: 'testepost01', component: () => import('@/views/labs/posts/testePost.vue') },
  //{ path: '/labs/pessoas01', name: 'pessoas01', component: () => import('@/views/labs/pessoas/pessoas01.vue') },

  //{ path: '/labs/api', name: 'api', component: () => import('@/views/labs/api/api.vue') },
  //{ path: '/labs/recursos', name: 'api', component: () => import('@/views/labs/recursos/recursos.vue') },
  { path: '/labs/docx', name: 'docx', component: () => import('@/views/labs/docx/docx.vue') },


  //{ path: '/buildnanos', name: 'buildnanos', component: () => import('@/views/labs/buildNanos/buildnanos.vue') },
  //{ path: '/labs/tema', name: 'tema', component: () => import('@/views/labs/temas/tema.vue') },
  //{ path: '/formacoes', name: 'formacoes', component: () => import('@/views/Formacoes.vue') },
  { path: '/profile', name: 'profile', component: () => import('@/views/profile/profile.vue') },
  { path: '/live', name: 'live', component: () => import('@/views/live/live.vue') },
  //{ path: '/curso/:id', name:'curso', props: true, component: () => import('@/views/curso.vue') },
  //{ path: '/auth/linkedin', name:'linkedinauth', props: true, component: () => import('@/views/linkedinAuth.vue') },

  { name: 'Area', path:'/area/:id', props: true, component: () => import('@/views/area/AreaView.vue') },

  //{ path: '/planos01', name: 'planos01', component: () => import('@/views/cursos/planosv2/lab/planos01.vue') },

  //{ path: '/pessoas', name: 'Pessoas', component: () => import('@/views/pessoas/pessoas.vue') },
  //{ path: '/usuarios', name: 'usuarios', component: () => import('@/views/user/usuariosView.vue') },
  { path: '/admin', name: 'admin', component: () => import('@/views/admin/adminView.vue') },
  { path: '/setores', name: 'setores', component: () => import('@/views/setoresView.vue') },

  { path: '/calendario', name: 'calendario', component: () => import('@/views/calendarios/calendarioHome.vue') },

  { path: '/avaliacao', name: 'avaliacao', component: () => import('@/views/avaliacao/avaliacaoHome.vue') },

  { path: '/formacaodocente', name: 'formacaodocente', component: () => import('@/views/formacaoDocente/formacaoDocente.vue') },
  { path: '/formacaodocente/ferramenta', name: 'fdferramenta', component: () => import('@/views/formacaoDocente/ferramenta.vue') },


  { name: 'public', path:'/public/:id', props: true, component: () => import('@/views/public/router.vue') },
  { name: 'plugin', path:'/plugin/:id', props: true, component: () => import('@/views/public/router2.vue') },


  //{ path: '/labs/xlsx', name: 'xlsx', component: () => import('@/views/labs/parseXLSX/xlsx.vue') },
  //{ path: '/labs/xlsx2', name: 'xlsx2', component: () => import('@/views/labs/parseXLSX/xlsx2.vue') },
  //{ path: '/labs/xlsx3', name: 'xlsx3', component: () => import('@/views/labs/parseXLSX/xlsx3.vue') },
  //{ path: '/recursos/xlsx4', name: 'xlsx4', component: () => import('@/views/Recursos/labs/xlsx4.vue') },
  //{ path: '/labs/xlsx5MG', name: 'xlsx5MG', component: () => import('@/views/labs/parseXLSX/xlsx5MG.vue') },

  //{ path: '/recursos', name: 'Recursos', component: () => import('@/views/Recursos/Home.vue') },
  //{ path: '/recursos/instrucoes', name: 'instrucoes', component: () => import('@/views/Recursos/instrucoes.vue') },
  //{ path: '/recursos/problemas', name: 'problemas', component: () => import('@/views/Recursos/problemas.vue') },
  //{ path: '/recursos/meusrecursos', name: 'meusrecursos', component: () => import('@/views/Recursos/meusRecursos/meusRecursos.vue') },
  //{ path: '/recursos/pareceristas', name: 'pareceristas', component: () => import('@/views/Recursos/pareceristas.vue') },
  //{ path: '/recursos/ucs', name: 'ucs', component: () => import('@/views/Recursos/ucs.vue') },
  //{ path: '/recursos/admrecursos', name: 'admrecursos', component: () => import('@/views/Recursos/admRecursos.vue') },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
