import { rdb, ServerValue } from '@/firebasedb.js';

export default {
  namespaced: true,
  state: () => ({
    topicos: {
      topicos: {},
      contadores: { total: 0 },
      loading: true,
      iniciado: false,
    }
  }),
  mutations: {
    setIniciado(state) {
      state.topicos.iniciado = true;
    },
    setTotal(state,value) {
      console.log("value",value);
      state.topicos.contadores.total = value;
      state.topicos.loading = false;
    },
    setTopicos(state,value) {
      console.log("value",value);
      state.topicos.conteudos = value;
      state.topicos.loading = false;
    },
  },
  actions: {
    init(context) {
      console.log("init:",context.state.topicos.iniciado);
      if(!context.state.topicos.iniciado) {
        rdb.ref('/curriculo/topicos/contadores/total').on('value', function(snap) {
          console.log("snap.val()",snap.val());
          if(snap.val() == null) {
            context.commit('setTotal',0)
          } else {
            context.commit('setTotal',snap.val());
          }
        });
        rdb.ref('/curriculo/topicos/topicos').on('value',function(snap) {
          if(snap.val() == null) {
            context.commit('setTopicos',{})
          } else {
            context.commit('setTopicos',snap.val());
          }
        });
        context.commit('setIniciado');
      }
    },
  },
  getters: {

  }
}
