
import chroma from "chroma-js";
import { buildPalette } from "./tools.js"

var palettes = [];
palettes.push(buildPalette({
  tone: "blue", name: "Ocean Threads",
  "P": { _: '#2F6D80' },
  "S": { _: '#7a99f5' },
  "I": { _: '#4DCDC9' },
  "V": { _: '#0eaa65' },
  "W": { _: '#F7D330' },
  "D": { _: '#EE3A5F' },
  "B": { _: '#F5F8FA' },
  "K": { _: '#092b3f' }
}));
palettes.push(buildPalette({
  tone: "blue", name: "Ocean Threads",
  "P": { _: '#164858' },
  "S": { _: '#acc0fb' },
  "I": { _: '#27b8c1' },
  "V": { _: '#0eaa65' },
  "W": { _: '#F7D330' },
  "D": { _: '#EE3A5F' },
  "B": { _: '#F5F8FA' },
  "K": { _: '#030d13' }
}));
palettes.push(buildPalette({
  tone: "blue", name: "Ocean Threads",
  "P": { _: '#165834' },
  "S": { _: '#acfbc0' },
  "I": { _: '#4dcd6d' },
  "V": { _: '#1ae58b' },
  "W": { _: '#f79630' },
  "D": { _: '#ee3a66' },
  "B": { _: '#f5faf6' },
  "K": { _: '#052a14' }
}));
palettes.push(buildPalette({
  tone: "red", name: "Strawberry Froth",
  "P": { _: '#701109' },
  "S": { _: '#e3b9b9' },
  "I": { _: '#9e6f6c' },
  "V": { _: '#1ae58b' },
  "W": { _: '#F56B39' },
  "D": { _: '#df0d00' },
  "B": { _: '#fdfafa' },
  "K": { _: '#260502' }
}));
palettes.push(buildPalette({
  tone: "red", name: "Strawberry Froth",
  "P": { _: '#1164FF' },
  "S": { _: '#5F7389' },
  "I": { _: '#00A6B5' },
  "V": { _: '#00A387' },
  "W": { _: '#F0B26B' },
  "D": { _: '#EA295E' },
  "B": { _: '#ffffff' },
  "K": { _: '#132144' }
}));
palettes.push(buildPalette({
  tone: "red", name: "Vuex Dark",
  "P": { _: '#0F1642' },
  "S": { _: '#B8C2CC' },
  "I": { _: '#242C78' },
  "V": { _: '#28C76F' },
  "W": { _: '#F0B26B' },
  "D": { _: '#DA566A' },
  "B": { _: '#ffffff' },
  "K": { _: '#2B3253' },
  active: { "dark": false, "P": '#0F1642', "S": '#B8C2CC', "I": '#5e64a7', "V": '#28C76F', "W": '#F0B26B', "D": '#DA566A',
  "B": '#ffffff', "b": '#eeebf4', "K": '#2B3253', },
  inverse: { "dark": true, "P": '#7E72F2', "S": '#126DB7', "I": '#B8C2CC', "V": '#28C76F', "W": '#F0B26B', "D": '#DA566A',
  "B": '#0F1642', "b": '#2B3253', "K": '#ffffff', },
}));

//var palette = buildPalette({ name: "(dark blue) Follow the Flow", "L": '#9EBFC0', "LL": '#DCDFE0', "D": '#216260', "DD": '#0D3530' });
//var palette = buildPalette({ name: "(dark blue) Evening Woods", "L": '#5D7772', "LL": '#B6E2D3', "D": '#43616F', "DD": '#152D2E' });
//var palette = buildPalette({ name: "(dark red) Tea and Romance", "L": '#DEB3AD', "LL": '#F9F1F0', "D": '#E1999F', "DD": '#663635' });
//var palette = buildPalette({ name: "(Green) Healthy Leaves", "DD": '#3D550C', "D": '#59981A', "L": '#81B622', "LL": '#ECF87F' });
//var palette = buildPalette({ name: "(marrom) Rose Flamingo", "L": '#9EBFC0', "LL": '#DCDFE0', "D": '#216260', "DD": '#A49393' });
//var palette = buildPalette({ name: "Royal Blue", "LL": '#edf0f9', "L": '#c7d2f2', "D": '#214ED3', "DD": '#153084' });
//var palette = buildPalette({ name: "Royal Blue", "LL": '#c4ebe4', "L": '#228b75', "D": '#008080', "DD": '#364f3f' });
//var palette = buildPalette({ name: "Royal Blue", "LL": '#E1E7E0', "L": '#6AA4B0', "D": '#2F6D80', "DD": '#2B4560' });
//var palette = buildPalette({ name: "Curious Blue", "LL": '#EEF6F8', "L": '#857F93', "D": '#1F94D6', "DD": '#0A2E43' });
//var palette = buildPalette({ name: "Curious Green", "LL": '#edf8fb', "L": '#b2e2e2', "D": '#2ca25f', "DD": '#006d2c' });
//console.log(palettes);

export const colors = {
    chroma: chroma,
    palette: palettes[5],
};
