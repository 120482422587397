import Vue from 'vue'
import { mixcolors } from "@/mixins/mixcolors.js"
import { mixconsole } from "@/mixins/mixconsole.js"
import { mixtoast } from "@/mixins/mixtoast.js"
import { mixlogin } from "@/mixins/mixlogin.js"
import LZString from "./lz-string.js"

import { v_icon } from "./directives/v-icon.js"
import { v_color, v_colorHex } from "./directives/v-color.js"
import { v_bgcolor } from "./directives/v-bgcolor.js"
import { db, rdb, snapshotValToArray, snapshotValToArraySort } from '@/firebasedb.js'


export default {
  // called by Vue.use(FirstPlugin)
  install(Vue) {

    // create a mixin
    Vue.mixin(mixcolors);
    Vue.mixin(mixconsole);
    Vue.mixin(mixtoast);
    Vue.mixin(mixlogin);

    // define a global property
    Vue.prototype.VERSION = 'v2.0.3';
    Vue.prototype.LZString = LZString;
    Vue.prototype.db = db;
    Vue.prototype.rdb = rdb;
    Vue.prototype.snapshotValToArray = snapshotValToArray;
    Vue.prototype.snapshotValToArraySort = snapshotValToArraySort;
    Vue.prototype.recursosA2 = { ucs: null, profs: null, agrupamentos: null };

    Vue.prototype.eventBus = new Vue();

    // add a custom directive
    Vue.directive('i', v_icon);
    Vue.directive('c', v_color)
    Vue.directive('cHex', v_colorHex)
    Vue.directive('bg', v_bgcolor)

    Vue.directive('teste', {
      bind: function (el,binding) {
        //console.log("DIRETIVA bind TESTE ",binding.value);
      },
      update: function (el,binding,vnode) {
        //console.log("DIRETIVA update TESTE ",binding.value);
        //console.log("DIRETIVA update TESTE ",vnode.context);
        vnode.componentOptions.propsData.color = "primary";
        //console.log("DIRETIVA update TESTE ",vnode.componentOptions.propsData);
      }
    })

    Vue.directive('scroll', {
      bind: function (el,binding) {
        let f = function(evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
        //console.log("passei bind v-scroll");
      },
      inserted: function(el, binding) {
        let f = function(evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
        //console.log("passei inserted v-scroll");
      }
    })


    // add a custom directive
    Vue.directive('fundoanim', {
      // When the bound element is inserted into the DOM...
      bind: function (el) {
        el.style.backgroundImage = "repeating-linear-gradient(135deg, rgba(18,18,18, 0.04) 0px, rgba(18,18,18, 0.04) 2px,transparent 2px, transparent 4px),linear-gradient(90deg, rgba(228,225,225, 0.9),rgba(228,225,225, 0.9))";
      }
    })


  }
}
